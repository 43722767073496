import 'core-js/es';

import {
  loadElementScrollPolyfill,
  loadResizeObserverPolyfill,
  loadObjectFromEntriesPolyfill,
  loadFindLastIndexPolyfill,
  loadWebStreamsPolyfill,
  loadFlatMapPolyfill,
  loadWithResolversPolyfill,
  loadPerformanceNowPolyfill,
} from './polyfills';

Promise.all([
  loadElementScrollPolyfill(),
  loadResizeObserverPolyfill(),
  loadObjectFromEntriesPolyfill(),
  loadFindLastIndexPolyfill(),
  loadFlatMapPolyfill(),
  loadWebStreamsPolyfill(),
  loadWithResolversPolyfill(),
  loadPerformanceNowPolyfill(),
]).then(() => {
  import('./app');
});
